import { createSlice } from '@reduxjs/toolkit'


export const Pretzels = createSlice({
    name: 'pretzels',
    initialState : {
        cookies : null,
        apples : null,
        rods : null,
        oreos : null,
        candy : null,
        bags : null,
    },
    reducers: {
        setCookies(state,action) {
            state.cookies = action.payload;
        },
        setApples(state,action) {
            state.apples = action.payload;
        },
        setRods(state,action) {
            state.rods = action.payload;
        },
        setOreos(state,action) {
            state.oreos = action.payload;
        },
        setCandy(state,action) {
            state.candy = action.payload;
        },
        setBags(state,action) {
            state.bags = action.payload;
        },
    },
})

// Action creators are generated for each case reducer function
export const { setApples , setOreos , setRods , setBags , setCandy , setCookies } = Pretzels.actions

export default Pretzels.reducer