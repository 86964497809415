import React from "react";
import { useNavigate } from "react-router-dom";
import { cashewImg } from "../../constant/Index";
import { filePreviewer } from "../../constant/helpers";
import { COOKIES, SHOP_DETAIL } from "../../routes/RouteLinks";

const Item = (props) => {
	const navigate = useNavigate();

	return (
		<>
			<div
				className="card-wrapper wow animate__animated animate__flipLeft align-items-stretch"
				onClick={() =>
					navigate(
						props?.item?.product_type !== "cookies"
							? SHOP_DETAIL(props?.item?.slug)
							: COOKIES,
					)
				}
				// onClick={()=>navigate(SHOP_DETAIL(props?.item?.id))}
				style={{
					width: "25%",
					padding: "0px",
				}}
			>
				<img
					src={filePreviewer(props?.item?.image)}
					className="img-fluid"
					alt=""
				/>
				<div className="card-border-heading d-flex flex-column">
					<h6>{props?.item?.title}</h6>
					<p style={{ color: "#6a6a6a" }}>
						{props?.item?.description?.slice(0, 57) +
							(props?.item?.description.length > 57 ? "..." : "")}
					</p>

					<h5>
						{" "}
						{props?.item?.product_type !== "cookies" && (
							<span>${parseFloat(props?.item?.price)?.toFixed(2)}</span>
						)}{" "}
					</h5>

					{/*<Link to={SHOP_DETAIL(props?.item?.id)}>*/}
					<button
						onClick={() =>
							navigate(
								props?.item?.product_type !== "cookies"
									? SHOP_DETAIL(props?.item?.slug)
									: COOKIES,
							)
						}
						type={"button"}
						className={`btn11 ${SHOP_DETAIL(
							props?.item?.slug,
						)} align-self-end mx-auto`}
					>
						View
					</button>
					{/*</Link>*/}
				</div>
			</div>
		</>
	);
};

export default Item;
