import PublicRoutes from "./routes/PublicRoutes";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import { BrowserRouter, useNavigate } from "react-router-dom";
import Error from "./components/Error/Error";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
	getDonateReasonsRequest,
	getSettingRequest,
} from "./redux/Setting/Action";
import { FooterLogo, Logo } from "./constant/Index";
import axios from "axios";
import { logout } from "./redux/Auth/Reducer";
import { setError } from "./redux/Errors/Reducer";
import { HOME } from "./routes/RouteLinks";
function App() {
	const dispatch = useDispatch();
	const loading = useSelector((state) => state?.loader?.loading);

	useEffect(() => {
		(async () => {
			await dispatch(getSettingRequest());
			await dispatch(getDonateReasonsRequest());
		})();
	}, []);

	return (
		<BrowserRouter>
			<div
				id="introduction"
				style={{
					height: loading ? "100vh" : "0vh",
				}}
			>
				<div className="slide-1 slide"></div>
				<div className="slide-2 slide"></div>
				<div className="image-container">
					<img
						className="banner-logo"
						src={FooterLogo}
						style={{ width: "10%" }}
						alt="banner"
						title="banner"
					/>
				</div>
			</div>

			{/* Header Start Here */}
			<Header />
			{/* Header End Here */}

			<PublicRoutes />

			{/* Error Here */}
			<Error />
			{/* Error Here */}

			{/* Footer Start Here */}
			<Footer />
			{/* Footer End Here */}
		</BrowserRouter>
	);
}

export default App;
