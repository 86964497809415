import React from "react";
import { Route, Routes } from "react-router-dom";
import About from "../pages/About/About";
import Home from "../pages/Home/Home";
import Shop from "../pages/Shop/Shop";
import ContactUs from "../pages/ContactUs/ContactUs";
import {
	ABOUT,
	APPLES,
	BAGS,
	BlOG,
	BlOGS,
	CANDY,
	CARE_PACKAGE,
	CART,
	CHECKOUT,
	CHOCOLATE_PLATTERS,
	CONTACTUS,
	COOKIES,
	DASHBOARD,
	DONATE,
	EVENT,
	FAQS, FILTER,
	FORGOT,
	HOME,
	INGREDIENT,
	LOGIN,
	ORDERDETAIL,
	OREOS,
	PRIVACY_POLICY,
	RESET_PASSWORD,
	RODS,
	SHOP,
	SHOP_DETAIL,
	SHOPDETAIL,
	SIGNUP,
	TERM_CONDITION,
	THANKYOU,
	WISHLIST,
} from "./RouteLinks";
import TermCondition from "../pages/Pages/TermCondition";
import PrivacyPolicy from "../pages/Pages/PrivacyPolicy";
import Detail from "../pages/Shop/Detail";
import Cart from "../pages/Cart/Cart";
import Login from "../pages/Auth/Login";
import SignUp from "../pages/Auth/SignUp";
import Dashboard from "../pages/Dashboard/Dashboard";
import { ProtectedRoute } from "./ProtectedRoute";
import Blogs from "../pages/Blogs/Blogs";
import BlogDetail from "../pages/Blogs/BlogDetail";
import Faqs from "../pages/Faqs/Faqs";
import Donate from "../pages/Donate/Donate";
import SpecialEvents from "../pages/Events/SpecialEvents";
import CarePackage from "../pages/CarePakage";
import CarePackageDetail from "../pages/CarePakage/detail";
import Ingredient from "../pages/Ingredient/index";
import Checkout from "../pages/Checkout";
import ChocolatePlatters from "../pages/ChocolatePlatters/index";
import Apples from "../pages/ChocolatePlatters/Apples";
import Bags from "../pages/ChocolatePlatters/Bags";
import Candy from "../pages/ChocolatePlatters/Candy";
import Oreos from "../pages/ChocolatePlatters/Oreos";
import Rods from "../pages/ChocolatePlatters/Rods";
import WishList from "../pages/WishList/WishList";
import ThankYou from "../pages/ThankYou";
import Cookies from "../pages/ChocolatePlatters/Cookies";
import Forgot from "../pages/Auth/Forgot";
import Reset from "../pages/Auth/Reset";
import OrderDetail from "../pages/Dashboard/OrderDetail";
import Other from "../pages/Other/Other";
import LandingPage from "../pages/Shop/LandingPage";

const PublicRoutes = () => {
	return (
		<>
			{/*<BrowserRouter>*/}
			<Routes forceRefresh={true}>
				<Route path={HOME} element={<Home />} />
				<Route path={ABOUT} element={<About />} />
				<Route path={FILTER} element={<LandingPage />} />
				<Route path={SHOP + "/:category?"} element={<Shop />} />
				<Route path={SHOPDETAIL + "/:id"} element={<Detail />} />
				<Route path={CONTACTUS} element={<ContactUs />} />
				<Route path={TERM_CONDITION} element={<TermCondition />} />
				<Route path={PRIVACY_POLICY} element={<PrivacyPolicy />} />
				<Route path={CART} element={<Cart />} />
				<Route path={LOGIN} element={<Login />} />
				<Route path={SIGNUP} element={<SignUp />} />
				<Route path={FORGOT} element={<Forgot />} />
				<Route path={RESET_PASSWORD} element={<Reset />} />
				<Route path={FAQS} element={<Faqs />} />
				<Route path={DONATE} element={<Donate />} />
				<Route path={INGREDIENT} element={<Ingredient />} />
				<Route path={BlOGS} element={<Blogs />} />
				<Route path={BlOG + "/:id"} element={<BlogDetail />} />
				<Route path={EVENT} element={<SpecialEvents />} />
				<Route path={CARE_PACKAGE} element={<CarePackage />} />
				<Route path={CHOCOLATE_PLATTERS} element={<ChocolatePlatters />} />
				<Route path={APPLES} element={<Apples />} />
				<Route path={BAGS} element={<Bags />} />
				<Route path={CANDY} element={<Candy />} />
				<Route path={OREOS} element={<Oreos />} />
				<Route path={RODS} element={<Rods />} />
				<Route path={COOKIES} element={<Cookies />} />
				<Route path={THANKYOU} element={<ThankYou />} />
				<Route path={CARE_PACKAGE + "/:id"} element={<CarePackageDetail />} />
				<Route
					path={CHECKOUT}
					element={
						<>
							<Checkout />
						</>
					}
				/>
				{/* Auth Routes */}
				{/*<Route element={ <ProtectedRoute /> }>*/}
				<Route
					path={DASHBOARD}
					element={
						<ProtectedRoute>
							<Dashboard />
						</ProtectedRoute>
					}
				/>
				<Route
					path={WISHLIST}
					element={
						<ProtectedRoute>
							<WishList />
						</ProtectedRoute>
					}
				/>
				<Route
					path={ORDERDETAIL + "/:id"}
					element={
						<ProtectedRoute>
							<OrderDetail />
						</ProtectedRoute>
					}
				/>
				{/*</Route>*/}
				{/* Auth Routes */}
			</Routes>
			{/*</BrowserRouter>*/}
		</>
	);
};

export default PublicRoutes;
