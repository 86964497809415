import React from "react";
import Banner from "../Home/Banner";

const ContentSection = (props) => {

	return (
		<section className="term-condition">
			<div className="container">
				<div className="row">
					<div className="col-lg-12">
						<div className="term-wrapper">
							<p dangerouslySetInnerHTML={{__html: props?.content?.content}}  />
						</div>
					</div>
				</div>
			</div>
		</section>
		)
};

export default ContentSection;
