import React from 'react';
import {onDirectChangeHandler, onInputChangeHandler} from "../../constant/helpers";
// import Input from 'react-phone-number-input/input';
// import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import {CustomPhoneNumber} from "../FormComponent";

const FormSection = ({ form , setForm }) => {


    return (
        <>
            <div className="row">
                <div className="col-lg-6">
                    <h3>
                        Billing Details
                    </h3>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-6">
                    <div className="form-group">
                        <label htmlFor="exampleFormControlInput1">First Name <span>*</span>
                        </label>
                        <input type="text" className="form-control"
                               name={'first_name'} value={form?.first_name}
                               required={true} onChange={(e)=>onInputChangeHandler(e,setForm)}
                               id="exampleFormControlInput1"
                               placeholder="Enter Your Name" />
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="form-group">
                        <label htmlFor="exampleFormControlInput1">Last
                            Name <span>*</span></label>
                        <input type="text" className="form-control"
                               name={'last_name'} value={form?.last_name}
                               required={true} onChange={(e)=>onInputChangeHandler(e,setForm)}
                               id="exampleFormControlInput1"
                               placeholder="Enter Last Name" />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-6">
                    <div className="form-group">
                        <label htmlFor="exampleFormControlInput1">Phone <span>*</span></label>
                        {/* <div className={"form-group"}> */}
                            {/*<Input*/}
                            {/*    className={"form-control"}*/}
                            {/*    required={true}*/}
                            {/*    style={{width:'100%'}}*/}
                            {/*    placeholder="Enter Phone"*/}
                            {/*    value={form?.contact_no}*/}
                            {/*    onChange={(e)=>onDirectChangeHandler('contact_no',e,setForm)}/>*/}
                            {/*<PhoneInput*/}
                            {/*    onlyCountries={['fr', 'at']}*/}
                            {/*    masks={{at: '(....) ...-....'}}*/}
                            {/*/>*/}
                            <CustomPhoneNumber
                                value={form?.contact_no}
                                onChange={(e)=>{
                                    onDirectChangeHandler('contact_no',e.target.value,setForm)
                                }}
                            />
                        {/* </div> */}
                        {/*<input type="tel" className="form-control"*/}
                        {/*       name={'contact_no'} value={form?.contact_no}*/}
                        {/*       required={true} onChange={(e)=>onInputChangeHandler(e,setForm)}*/}
                        {/*       id="exampleFormControlInput1"*/}
                        {/*       placeholder="Enter Phone" />*/}
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="form-group">
                        <label htmlFor="exampleFormControlInput1">Email <span>*</span></label>
                        <input type="email" className="form-control"
                               name={'email'} value={form?.email}
                               required={true} onChange={(e)=>onInputChangeHandler(e,setForm)}
                               id="exampleFormControlInput1"
                               placeholder="Enter Email" />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12">
                    <div className="form-group">
                        <label htmlFor="exampleFormControlInput1">Address <span>*</span></label>
                        <input type="text" className="form-control"
                               name={'address'}
                               value={form?.address}
                               required={true}
                               onChange={(e)=>onInputChangeHandler(e,setForm)}
                               id="exampleFormControlInput1"
                               placeholder="Enter Your Address" />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12">
                    <div className="form-group">
                        <label htmlFor="exampleFormControlInput1">Add Donation <span
                            className="option">(optional)</span></label>
                        <input type="number" className={`form-control ${form?.payment_type}`}
                               name={'donation_amount'} value={form?.donation_amount}
                               onChange={(e)=>onInputChangeHandler(e,setForm)}
                               id="exampleFormControlInput1"
                               // disabled={true}
                               disabled={( form?.payment_type === "pay_now" && form?.fee === true )? true : false}
                               placeholder="Enter Your Amount " />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12">
                    <div className="form-group">
                        <label htmlFor="note">Special Instruction</label>
                        <input
                            type="text"
                            className="form-control"
                            name={'note'}
                            value={form?.note}
                            required={true}
                            onChange={(e)=>onInputChangeHandler(e,setForm)}
                            id="note"
                            placeholder="Enter Your Special Instruction"
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

const Form =  React.memo(FormSection);
export default Form;