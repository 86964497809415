import React, {useEffect, useMemo} from 'react';
import useCalender from "../../hooks/useCalender";
import {filterMatchDateData} from "../../constant/helpers";
import moment from "moment";
import {setDates} from "../../redux/Setting/Reducer";
import {useDispatch} from "react-redux";
import {getDatesRequest} from "../../redux/Setting/Action";

const CalenderCop = (props) => {

    const {
        monthNames,
        selectedDate,
        calendarRows,
        getPrevMonth,
        getNextMonth,
        matchCurrentDate,
        setSelectedDate,
    } = useCalender();

    const dispatch = useDispatch();

    useEffect(() => {
    //
        props?.setOrderDate(selectedDate);
    //
    //     if (moment(selectedDate).format('MMM') !== "Dec") {
    //
    //         let daysInMonth = [];
    //         let monthDate = moment(selectedDate).startOf('month');
    //
    //             [...new Array(monthDate.daysInMonth())].map((val,key)=>{
    //             daysInMonth.push(monthDate.format('YYYY-MM-DD'));
    //             monthDate.add(1, 'day');
    //
    //         });
    //         dispatch(setDates(daysInMonth))
    //
    //     }
    //     else{
    //         dispatch( getDatesRequest())
    //     }
    //
    //     console.log("Dates",props?.dates);

    }, [selectedDate])


    return (
        <div id="datepicker_scnd" className="hasDatepicker">
            <div
                className="ui-datepicker-inline ui-datepicker ui-widget ui-widget-content ui-helper-clearfix ui-corner-all"
                style={{display: 'block'}}>
                <div className="ui-datepicker-header ui-widget-header ui-helper-clearfix ui-corner-all">
                    <a
                        className="ui-datepicker-prev ui-corner-all"
                        onClick={getPrevMonth}>
                            <span className="ui-icon ui-icon-circle-triangle-w">
                                <i className="fa fa-regular fa-arrow-left"></i>
                            </span>
                    </a>
                    <a className="ui-datepicker-next ui-corner-all"
                       onClick={getNextMonth}>
                        <span className="ui-icon ui-icon-circle-triangle-e">
                            <i className="fa fa-regular fa-arrow-right"></i>
                        </span>
                    </a>
                    <div className="ui-datepicker-title"><span className="ui-datepicker-month">
                        {monthNames[selectedDate.getMonth()]}
                    </span>&nbsp;

                    </div>
                </div>
                <table className="ui-datepicker-calendar">
                    <thead>
                    <tr>
                        <th scope="col"><span title="Monday">Mo</span></th>
                        <th scope="col"><span title="Tuesday">Tu</span></th>
                        <th scope="col"><span title="Wednesday">We</span></th>
                        <th scope="col"><span title="Thursday">Th</span></th>
                        <th scope="col"><span title="Friday">Fr</span></th>
                        <th scope="col" className="ui-datepicker-week-end"><span title="Saturday">Sa</span></th>
                        <th scope="col" className="ui-datepicker-week-end"><span title="Sunday">Su</span></th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        Object.keys(calendarRows).map((row, index) => (
                            <tr key={index}>
                                {
                                    calendarRows[row]?.map((val, key) => (
                                        <td key={key}
                                            className={`${matchCurrentDate(val?.date) ? 'ui-datepicker-current-day' : ''} ${(val?.day === "Sat" || val?.day === "Sun") ? 'ui-datepicker-week-end' : ''}`}>
                                            <a onClick={() => {
                                                if (val?.classes === "in-prev-month") {
                                                    getPrevMonth();
                                                } else if (val?.classes === "in-next-month") {
                                                    getNextMonth();
                                                } else {
                                                    setSelectedDate(val?.dateObj);
                                                }

                                            }}
                                               className={`ui-state-default ${matchCurrentDate(val?.date) ? 'ui-state-active' : ''}`}
                                               style={{
                                                   color: `${(() => {
                                                       if (val?.classes === "in-prev-month" || val?.classes === "in-next-month") {
                                                           return '#d1d1d1';
                                                       } else {
                                                           return (matchCurrentDate(val?.date)) ? '#fff' : '#000';
                                                       }
                                                   })()}`
                                               }}>
                                                {val?.value}
                                                {
                                                    (
                                                        !matchCurrentDate(val?.date) &&
                                                        !(val?.classes === "in-prev-month" || val?.classes === "in-next-month") &&
                                                        filterMatchDateData(val?.date, props?.dates).length > 0
                                                    ) &&
                                                    <sup className={"font-weight-bold"}
                                                         style={{color: '#c98c11'}}>*</sup>
                                                }
                                            </a>
                                        </td>
                                    ))
                                }
                            </tr>
                        ))
                    }
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default CalenderCop;