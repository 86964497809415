import React, {useEffect, useLayoutEffect} from "react";
import ContentSection from "./ContentSection";
import {useDispatch, useSelector} from "react-redux";
import {getPageRequest} from "../../redux/Setting/Action";
import InnerBanner from "../../components/InnerBanner/InnerBanner";
import {setLoader} from "../../redux/Loader/Reducer";

const TermCondition = () => {

	const dispatch = useDispatch();
	const page = useSelector(state => state?.setting?.page);

	const get = async () => {
		dispatch(setLoader(true));
		await dispatch(getPageRequest('terms'));
		window.scrollTo(0, 0);
		dispatch(setLoader(false));
	}

	useEffect(()=>{
		window.scrollTo(0, 0);
		// setTimeout(() => {
		// 	window.scrollTo(0, 0);
		// }, 500);
		get();
	},[])

	return (
		<>
			{/* Banner Start Here */}
			<InnerBanner PageName={'TERMS & CONDITIONS'}/>
			{/* Banner End Here */}
			<ContentSection content={page} />
		</>
	);
};

export default TermCondition;
