import React, { useState } from "react";
import Package from "./Package";
import OrderPlace from "../Event/OrderPlace";
import { placeOrderPackageRequest } from "../../redux/Packages/Action";
import Select from "react-select/base";
import AsyncSelect from "react-select/async";
import MultiSelect from "../MultiSelect/Index";
import { filePreviewer, filterInSelectForm } from "../../constant/helpers";
import { useDispatch } from "react-redux";
import Slider from "../Slider/index";

const BottomDetailSection = ({ item }) => {
	const [flavor, setFlavor] = useState([]);
	const dispatch = useDispatch();

	const onSubmitHandler = async (form) => {
		let x = await dispatch(
			placeOrderPackageRequest({
				...form,
				package_id: item?.id,
				flavors: JSON.stringify(flavor?.map((val) => val?.value)),
			}),
		);
		return x;
	};

	return (
		<>
			<section className="testimoniel">
				<div className="container">
					<div className="row">
						<div className="col-lg-6">
							<Slider attachments={item?.attachments || []} />
						</div>
						<div className="col-lg-6">
							<div className="main-slider-cookies">
								<div className="cookies-slider-heading-wrapper">
									<div className="cookies-heading1">
										<h2>{item?.name}</h2>
									</div>
									{/* <div className="dollar-cookies">
										<h2 className="dollar-wrapper">
											<span>
												<i className="fa fa-sharp fa-solid fa-heart"></i>
											</span>
										</h2>
									</div> */}
								</div>
								<div className="description">
									<h4>Description:</h4>
									<p>{item?.description}</p>
								</div>
								<div className="qty-main">
									<div className="ingrediant-list">
										<h4>Package Includes</h4>
										<div className="list-wrapper mb-0">
											<ul>
												{item?.includes?.map((val, key) => (
													<li key={key}>
														<i className="fa fa-solid fa-check"></i>
														{val}
													</li>
												))}
											</ul>
										</div>
										<div className="form-selector">
											<div className="form-group mb-0">
												<label htmlFor="inputState">Select Flavor</label>
											</div>
											<div className="form-group">
												<MultiSelect
													onChange={(val) => setFlavor(val)}
													isMulti={true}
													className={"form-control"}
													value={flavor}
													isSearchable={true}
													options={filterInSelectForm(item?.products)}
												/>

												{/*<select id="inputState" onChange={(e)=>setFlavor(parseInt(e.target.value))} className="form-control">*/}
												{/*    <option selected value={''}>Select Flavor</option>*/}
												{/*    {*/}
												{/*        item?.products?.map((val,key)=>(*/}
												{/*            <option value={val?.id} key={key}>{val?.title}</option>*/}
												{/*        ))*/}
												{/*    }*/}
												{/*</select>*/}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className="care-pakage-second-wrapper">
				<div className="container">
					<div className="row">
						<div className="col-lg-6">
							<OrderPlace
								title={"Place Your Order Now"}
								content={`Complete information below with your request or call or email
                                to discuss your event and ideas with Sue.
                                Lead time needed and pricing will be confirmed during your discussion
                                with Sue.`}
								request={onSubmitHandler}
								type={"care-package"}
							/>
						</div>
						<div className="col-lg-6"></div>
					</div>
				</div>
			</section>
		</>
	);
};

export default BottomDetailSection;
