import React from "react";
import { useSelector } from "react-redux";
import Product from "../../components/Product/Product";
import {
	CookiesImg,
	LinkImg,
	Product1,
	Product2,
	Product3,
	Product4,
	Product5,
	RibbonImg2,
	RibImg,
	RingImg,
} from "../../constant/Index";
import {CARE_PACKAGE, CHOCOLATE_PLATTERS, COOKIES, EVENT, SHOP} from "../../routes/RouteLinks";

const Shop = () => {
	const home = useSelector(state=> state?.setting?.home);
	const shop = useSelector(state=> state?.setting?.shop);

	return (
		<>
			<section className="our-shop">
				<div className="side-image-1-row">
					<img src={CookiesImg} className="img-fluid cookies" alt="" />
					<img src={RibImg} className="img-fluid rib" alt="" />
					<img src={LinkImg} className="img-fluid link" alt="" />
				</div>
				<div className="container">
					<div className="row pb-5">
						<div className="col-lg-2"></div>
						<div className="col-lg-8">
							<div className="content-wrapper wow animate__animated animate__fadeInDown">
								{/* <h6>Our Shop</h6>
								<h2>
									Get our Cookies & Treats and fund pediatric cancer research
								</h2>
								<p>
									All net proceeds are donated to help fight pediatric cancer
								</p> */}
								<div dangerouslySetInnerHTML={{__html: home?.our_shop_heading}}></div>
							</div>
						</div>
						<div className="col-lg-2"></div>
					</div>
					<div className="row product-row">
						<div className="product-card-wrapper-main">
							{shop?.map((data, index) => (
								<Product
									key={index}
									Img={data?.image}
									name={data?.title}
									description={data?.content}
									link={(data?.slug === "cookies")? SHOP+'/cookies' :data?.link}
								/>
							))}
						</div>
					</div>
				</div>
				<div className="side-image-2-row">
					<img src={RingImg} className="img-fluid ring" alt="" />
					<img src={RibbonImg2} className="img-fluid ribion" alt="" />
					<img src={CookiesImg} className="img-fluid cookies" alt="" />
				</div>
			</section>
		</>
	);
};

export default Shop;
