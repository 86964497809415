import React, {useState} from "react";
import {useDispatch} from "react-redux";
import {newsletterRequest} from "../../redux/Newsletter/Action";

const Newsletter = () => {

	const dispatch = useDispatch();
	const [loading , setLoading] = useState(false);
	const [email , setEmail] = useState('');

	const onSubmitHandler = async (e) => {
		e.preventDefault();
		setLoading(true);
		let x = await dispatch(newsletterRequest({ email: email }));
		setLoading(false);
		if(x.payload){
			setEmail('');
		}
	}


	return (
		<>
			<section className="newsletter">
				<div className="container">
					<div className="row align-items-center">
						<div className="col-lg-4">
							<div className="heading-wrapper">
								<h4>Join Our Email List</h4>
							</div>
						</div>
						<div className="col-lg-3"></div>
						<div className="col-lg-5">
							<form onSubmit={onSubmitHandler}>
								<div className="newsletter-wrapper">
									<input required={true} value={email}
										   type="email" onChange={(e)=>setEmail(e.target.value)}
										   className="form-control"
										   placeholder="Enter Your Email"
									/>
									<button type={"submit"} disabled={loading} className="btn">
										{loading? <i className={'fa fa-spin fa-spinner'}></i> : 'Submit'}
									</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default Newsletter;
