import {createAsyncThunk} from "@reduxjs/toolkit";
import header from "../../components/Header/Header";
import {setError} from "../Errors/Reducer";
import axios from "axios";
import {
    categoryApi,
    filterPageApi,
    productApi,
    productDataApi,
    productsApi,
    relatedProductsApi
} from "../../Netwrok/api";
import {responseError} from "../../Netwrok/responseHandler";
import {setCategory, setProduct, setProductData, setProducts, setReleated} from "./Reducer";
import {headers} from "../headers";

export const getFilterPageRequest = createAsyncThunk('get-filter-request',async (
    url = null,
    {
        dispatch,
        rejectWithValue ,
        fulfillWithValue
    })=>{
    try {
        let resp = await axios.get(filterPageApi,headers());
        return fulfillWithValue({
            status : true,
            response : resp.data.response.data
        });
    }catch (e){
        dispatch(setError(responseError(e)));
        return  rejectWithValue(false);
    }
});

export const getProductsRequest = createAsyncThunk('get-products-request',async (
    url = null,
    {
        dispatch,
        rejectWithValue ,
        fulfillWithValue
    })=>{
    try {
        let link = (url)? url : productsApi;
        let resp = await axios.get(link,headers());
        dispatch(setProducts(resp.data.response.data));
        return fulfillWithValue(true);
    }catch (e){
        dispatch(setError(responseError(e)));
        return  rejectWithValue(false);
    }
});

export const getCategoryRequest = createAsyncThunk('get-category-request',async (
    url = null,
    {
        dispatch,
        rejectWithValue ,
        fulfillWithValue
    })=>{
    try {
        let resp = await axios.get(categoryApi,headers());
        dispatch(setCategory(resp.data.response.data));
        return fulfillWithValue(true);
    }catch (e){
        dispatch(setError(responseError(e)));
        return  rejectWithValue(false);
    }
});

export const getProductsDataRequest = createAsyncThunk('get-products-data-request',async (
    url = null,
    {
        dispatch,
        rejectWithValue ,
        fulfillWithValue
    })=>{
    try {
        let resp = await axios.get(productDataApi,headers());
        dispatch(setProductData(resp.data.response.data));
        return fulfillWithValue(true);
    }catch (e){
        dispatch(setError(responseError(e)));
        return  rejectWithValue(false);
    }
});

export const getRelatedProductsRequest = createAsyncThunk('get-related-products-request',async (
    url = null,
    {
        dispatch,
        rejectWithValue ,
        fulfillWithValue
    })=>{
    try {
        let resp = await axios.get(relatedProductsApi,headers());
        dispatch(setReleated(resp.data.response.data));
        return fulfillWithValue(true);
    }catch (e){
        dispatch(setError(responseError(e)));
        return  rejectWithValue(false);
    }
});

export const getProductRequest = createAsyncThunk('get-product-request',async (
    id,
    {
        dispatch,
        rejectWithValue ,
        fulfillWithValue
    })=>{
    try {
        let resp = await axios.get(productApi(id),headers());
        dispatch(setProduct(resp.data.response.data));
        return fulfillWithValue(true);
    }catch (e){
        dispatch(setError(responseError(e)));
        return  rejectWithValue(false);
    }
});