import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { filePreviewer } from "../../constant/helpers";
import { RibbonImg, RoundImg } from "../../constant/Index";
import {ABOUT} from "../../routes/RouteLinks";

const Banner = () => {
	const home = useSelector(state=> state?.setting?.home);
	return (
		<>
			{/* Banner Sec Start Here */}
			<section className="banner-sec" style={{background: `url(${filePreviewer(home?.banner_image)})`}}>
				<div className="container">
					<div className="row">
						<div className="col-lg-6">
							<div className="banner-content-wrapper wow animate__animated animate__fadeInLeft" >
								<img src={RibbonImg} alt="" />
								{/* <h6>wELCOME tO Cookies4aCure </h6> */}
								{/* <h2>Helping cure pediatric cancer, One cookie at a time</h2>
								<p>
									Its All Good Foundation Inc. Doing Business as Cookies4aCure
									is a 501c3 Charitable Foundation (EIN 61-1994609)
								</p> */}
								<div dangerouslySetInnerHTML={{__html: home?.content}}></div>
								<div className="btn-wrapper">
									{/* <!-- <Link href="add-donate.php" className="btn">Donate</Link> --> */}
									<Link to={ABOUT} className="btn">
										About Us
									</Link>
								</div>
							</div>
						</div>
						<div className="col-lg-6"></div>
					</div>
					<img src={RoundImg} className="round" alt="" />
				</div>
			</section>
			{/* Banner Sec End Here */}
		</>
	);
};

export default Banner;
