import React from "react";
import { useNavigate } from "react-router-dom";
import {
  changeElementValueInArray,
  filePreviewer,
} from "../../constant/helpers";
import { useDispatch, useSelector } from "react-redux";
import ReleatedSection from "./ReleatedSection";
import { LOGIN, SHOP } from "../../routes/RouteLinks";
import { addWishListRequest } from "../../redux/Wishlist/Action";
import Slider from "../Slider/index";

const DetailSection = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isLogin = useSelector((state) => state?.auth?.isLogin);
  const item = useSelector((state) => state?.product?.item);
  const wishListItem = useSelector((state) => state?.wishlist?.list);

  const onQtyChangeHandler = async (value, type) => {
    value = type === "+" ? value + 1 : value - 1;
    if (type === "-" && value >= 0) {
      props?.setQty((preVal) => preVal - 1);
    } else if (type === "+" && value >= 0 && value <= item?.stock_qty) {
      props?.setQty((preVal) => preVal + 1);
    }
  };


	return (
		<>
			<section className="testimoniel">
				<div className="container">
					<div className="row">
						<div className="col-lg-6">
							<Slider attachments={item?.attachments} />
						</div>
						<div className="col-lg-6">
							<div className="main-slider-cookies">
								<div className="cookies-slider-heading-wrapper">
									<div className="cookies-heading1">
										<h2>
											{item?.title}
                                            {/*{*/}
                                            {/*    wishListItem?.find((val) => parseInt(val?.product_id) === parseInt(item?.id) ) ?*/}
                                            {/*    <span>(Per)</span>*/}
                                            {/*        :*/}
                                            {/*        <span>(Per Dozen)</span>*/}
                                            {/*}*/}
										</h2>
									</div>
									<div className="dollar-cookies">
										<h2 className="dollar-wrapper">
											${parseFloat(item?.price)?.toFixed(2)}
											{!isLogin ? (
												<span role={"button"} onClick={() => navigate(LOGIN)}>
													<i className={`fa fa-sharp fa-solid fa-heart`}></i>
												</span>
											) : (
												<span
													role={"button"}
													onClick={() =>
														dispatch(
															addWishListRequest({
																product_id: item?.id,
															}),
														)
													}
												>
													<i className={`fa fa-sharp fa-solid ${
                                                        (wishListItem?.find( (val) => parseInt(val?.product_id) === parseInt(item?.id) ))?
                                                            'fa-heart'
                                                            :
                                                            'fa-heart-o'
                                                    }`}></i>
												</span>
											)}
										</h2>
									</div>
								</div>
								<div className="description">
									<h4>Description:</h4>
									<p>{item?.description}</p>
								</div>
								<div className="qty-main">
									<div className="row">
										<div className="col-lg-12">
											<div className="qty1">
												{/*<form action="">*/}
												<p className="qty" style={{ border: "0px" }}>
													<label htmlFor="qty" style={{ flex: "0 0 17%" }}>
														Qty <br /> <span>(Dozen)</span>
													</label>

                          <button
                            className="qtyminus"
                            onClick={() => onQtyChangeHandler(props?.qty, "-")}
                            type={"button"}
                            aria-hidden="true"
                            style={{ fontSize: "1rem" }}
                          >
                            <i className={"fa fa-minus fa-solid"}></i>
                          </button>

                          {/*<div className={"qtyDiv"}>{props?.qty}</div>*/}
                          <input
                            type="number"
                            name={"qty"}
                            value={props?.qty}
                            min={1}
                            maxLength={parseInt(item?.stock_qty)}
                            onChange={(e) =>
                              props?.setQty(parseInt(e.target.value))
                            }
                            className="qtyDiv"
                          />

                          <button
                            type={"button"}
                            onClick={() => onQtyChangeHandler(props?.qty, "+")}
                            className="qtyplus"
                            aria-hidden="true"
                            style={{ fontSize: "1rem" }}
                          >
                            <i className={"fa fa-plus fa-solid"}></i>
                          </button>
                        </p>
                        {/*</form>*/}
                      </div>
                    </div>
                  </div>
                  <div className="ingrediant-list">
                    <h4>Ingredients</h4>
                    <div className="list-wrapper" style={{marginBottom:"20px"}}>
                      <h6>{item?.ingredients}</h6>
                    </div>
                  </div>
                  <div className="out-of-stock-wrapper">
                    <ul>
                      <li>
                        <span>Stock :</span> <span>{item?.stock_qty}</span>
                      </li>
                    </ul>
                  </div>
                  <div className="duel-btns">
                    {parseInt(item?.stock_qty) === 0 ? (
                      <p className={"text-danger"}>Out of Stock</p>
                    ) : (
                      <>
                        {props?.qty > 0 && (
                          <button
                            onClick={() => {
                              props?.addToCart(item);
                            }}
                            className="btn2"
                          >
                            <i className="fa-solid fa-cart-shopping"></i>
                            Add to cart
                          </button>
                        )}
                        <button onClick={() => navigate(SHOP)} className="btn2">
                          <i className="fa-solid fa-cart-shopping"></i>
                          Continue Shopping
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/*<ReleatedSection />*/}
    </>
  );
};

export default DetailSection;
