export const baseUrl = 'https://cookies4acure.custom-ourbase.com/api/';
export const fileUrl = `https://cookies4acure.custom-ourbase.com/uploads/`;

// export const baseUrl =
// 	"http://phplaravel-899626-3395950.cloudwaysapps.com/api/";
// export const fileUrl = `http://phplaravel-899626-3395950.cloudwaysapps.com/uploads/`;

export const newsletterApi = `${baseUrl}newsletter/store`;

export const promoCodeApi = `${baseUrl}promo-code/check-promo-code`;

//Checkout
export const checkOutApi = `${baseUrl}orders/place_order`;

export const contactUsApi = `${baseUrl}contact-us/store`;

//Fees
export const payPalFeeApi = `${baseUrl}payment-fees/get-paypal-fee`;
export const stripeFeeApi = `${baseUrl}payment-fees/get-stripe-fee`;

//Blogs
export const blogsApi = `${baseUrl}blogs`;
export const blogDetailApi = (id) => `${baseUrl}blogs/${id}`;
//Packages
export const packagesApi = `${baseUrl}packages`;
export const packageApi = (id) => `${baseUrl}packages/${id}`;
export const packagePlaceOrderApi = `${baseUrl}package-orders/store`;

//Faqs
export const faqsApi = `${baseUrl}faqs`;

//Event Orders
export const eventOrderApi = `${baseUrl}event-orders/store`;

export const filterPageApi = `${baseUrl}cookie_page`;
export const homePageApi = `${baseUrl}pages/home`;
export const homeShopPageApi = `${baseUrl}page_infos`;
export const FactApi = `${baseUrl}facts`;
export const AboutApi = `${baseUrl}pages/about-us`;
export const TeamsApi = `${baseUrl}teams`;
export const EventPageApi = `${baseUrl}events`;
export const ingredientPageApi = `${baseUrl}pages/ingredients`;
export const pagesApi = (slug) => `${baseUrl}pages/${slug}`;
export const settingApi = `${baseUrl}settings`;
// export const datesApi = `${baseUrl}available-dates/get-all-dates`;
export const datesApi = `${baseUrl}slots`;

// Donation
export const donationsPageApi = `${baseUrl}donations`;
export const donationsReasonsApi = `${baseUrl}donation_reasons`;
export const payDonationApi = `${baseUrl}donation-payments/store`;
export const updateDonationApi = `${baseUrl}donation-payments/update`;

export const getProfileApi = `${baseUrl}user/profile`;
export const profileUpdateApi = `${baseUrl}user/profile/update`;
export const profilePicUpdateApi = `${baseUrl}user/profile/update-avatar`;
export const profilePicDeleteApi = `${baseUrl}user/profile/delete-avatar`;
export const changePasswordApi = `${baseUrl}user/profile/change-password`;
export const donationsApi = `${baseUrl}user/donations/my-donations`;
export const ordersApi = `${baseUrl}user/orders/my-orders`;
export const orderDetailApi = (id) => `${baseUrl}user/orders/my-order/${id}`;
export const wishListApi = `${baseUrl}user/wishlist`;
export const addWishListApi = `${baseUrl}user/wishlist/store`;
export const deleteWishListApi = (id) => `${baseUrl}user/wishlist/delete/${id}`;

export const productDataApi = `${baseUrl}products/simple-products`;
export const categoryApi = `${baseUrl}product_categories`;
export const productsApi = `${baseUrl}products`;
export const downloadProductsApi = `${baseUrl}products/download-excel`;
export const relatedProductsApi = `${baseUrl}products/related`;
export const productApi = (id) => `${baseUrl}products/${id}`;

// Auth Apis
export const loginApi = `${baseUrl}auth/login`;
export const resetPasswordApi = `${baseUrl}auth/reset-password`;
export const verifyOtpApi = `${baseUrl}auth/verify-otp`;
export const forgotPasswordApi = `${baseUrl}auth/forgot-password`;
export const signUpApi = `${baseUrl}auth/register`;

//pretzels
export const cookiesApi = `${baseUrl}platters/cookies`;
export const applesApi = `${baseUrl}platters/apples`;
export const rodsApi = `${baseUrl}platters/pretzel-rods`;
export const oreosApi = `${baseUrl}platters/chocolate-covered-oreos`;
export const candyApi = `${baseUrl}platters/hard-tack-candy`;
export const bagsOfPretzelsApi = `${baseUrl}platters/bags-of-pretzels`;

//Cards
export const getCardApi = `${baseUrl}user/cards`;
export const storeCardApi = `${baseUrl}user/cards/store`;
export const deleteCardApi = (id) => `${baseUrl}user/cards/delete/${id}`;
