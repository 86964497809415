import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {
    getApplesRequest, getBagsRequest,
    getCandyRequest, getCookiesRequest,
    getOreosRequest,
    getRodsRequest
} from "../../redux/ChocolateAndPretzels/action";
import '../../assets/css/chocolatte-platters.css';
import InnerBanner from "../../components/InnerBanner/InnerBanner";
import BottomSection from "./BottomSection";
import {setLoader} from "../../redux/Loader/Reducer";

const ChocolatePlatters = () => {

    const dispatch = useDispatch();
    const [loading , setLoading] = useState(false);
    const get = async () => {
        dispatch(setLoader(true));
        // setLoading(true);
        await dispatch(getApplesRequest());
        dispatch(setLoader(false));
        await dispatch(getRodsRequest());
        await dispatch(getOreosRequest());
        await dispatch(getCandyRequest());
        await dispatch(getBagsRequest());
        // await dispatch(getCookiesRequest());
        // setLoading(false);
    }

    useEffect(()=>{
        window.scrollTo(0, 0);
        get();
    },[])

    return (
        <>
        <section className="for-choclate-platters">
            {/* InnerBanner Start Here */}
            <InnerBanner PageName="Chocolate and Pretzels" />
            {/* InnerBanner End Here */}

            <BottomSection />
        </section>
        </>
    )
}

export default ChocolatePlatters;