import React from 'react';
import {chunks, filePreviewer} from "../../constant/helpers";
import {cookies, rib, ribion, shopgroup2, shopgroup3} from "../../constant/Index";

const BottomSection = ({data}) => {

    return (
        <section className="about-us-main-page">
            <div className="side-image-1-row">
                <img src={cookies} className="img-fluid cookies" alt=""/>
                <img src={rib} className="img-fluid rib" alt=""/>
                <img src={ribion} className="img-fluid link" alt=""/>
                <img src={shopgroup3} className="img-fluid links" alt=""/>
                <img src={shopgroup2} className="img-fluid links-wrapper" alt=""/>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 align-self-center">
                        <div className="about-heading">
                            <h2>{data?.page_title}</h2>
                            <p dangerouslySetInnerHTML={{__html: data?.section_1_content}}/>
                            <div className="row">

                                <div className="col-lg-6 col-md-6 pr-0">
                                    <div className="list-wrapper">
                                        <ul>
                                            {
                                                data?.ingredients?.filter((val, key) => (key <= (parseInt(data?.ingredients?.length / 2))))
                                                    ?.map((val, key) => (
                                                        <li key={key}>
                                                            <i className="fa fa-solid fa-circle"></i>
                                                            {val}
                                                        </li>
                                                    ))
                                            }
                                        </ul>
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6 pr-0">
                                    <div className="list-wrapper">
                                        <ul>
                                            {
                                                data?.ingredients?.filter((val, key) => (key > (parseInt(data?.ingredients?.length / 2))))
                                                    ?.map((val, key) => (
                                                        <li key={key}>
                                                            <i className="fa fa-solid fa-circle"></i>
                                                            {val}
                                                        </li>
                                                    ))
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <p dangerouslySetInnerHTML={{__html: data?.section_2_content}}/>
                    </div>

                    <div className="col-lg-6">
                        <div className="about-img-wrapper1">
                            <figure>
                                <img width={'100%'} src={filePreviewer(data?.section_2_image)} className="img-fluid"
                                     alt=""/>
                            </figure>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default BottomSection;