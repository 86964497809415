import React, { useEffect, useState } from "react";
import { CardElement, ElementsConsumer } from "@stripe/react-stripe-js";
import CardHandler from "./CardHandler";
import { visaimg } from "../../constant/Index";

const Component = (props) => {
	const [loading, setLoading] = useState(false);
	const [cardDetails, setCardDetails] = useState(null);

	const stripeTokenGenerator = async () => {
		setLoading(true);

		const { stripe, elements } = props;

		if (!stripe || !elements) {
			setLoading(false);
			return;
		}

		const card = elements.getElement(CardElement);
		const result = await stripe.createToken(card);
		if (result.error) {
			props.setStripeToken({ generate: false, token: "" });
			// console.log(result.error.message);
			setLoading(false);
		} else {
			setCardDetails(result.token?.card);
			props.setStripeToken({ generate: true, token: result.token?.id });
			setLoading(false);
		}
	};

	const onDirectTokenHandler = async () => {
		setLoading(true);

		const { stripe, elements } = props;

		if (!stripe || !elements) {
			setLoading(false);
			return;
		}

		const card = elements.getElement(CardElement);
		const result = await stripe.createToken(card);
		if (result.error) {
			props.setStripeToken({ generate: false, token: "" });
			// console.log(result.error.message);
			setLoading(false);
		} else {
			setCardDetails(result.token?.card);
			await props.customFunction(result.token?.id);
			setLoading(false);
		}
	};

	return (
		<>
			{props?.stripeToken?.generate ? (
				<div
					className={
						"form-control py-3 mb-2 d-flex flex-row justify-content-between align-items-center row checkout-visa-card-wrapper"
					}
				>
					 <p className={"col-lg-2 m-0 pr-3"}>{cardDetails?.brand}</p>
					{/*<p className={"col-lg-2 m-0 pr-3"}>*/}
					{/*	<img src={visaimg} className="img-fluid" alt="" />*/}
					{/*</p>*/}
					<p className={"col-lg-6 m-0 py-3 pr-3"}>
						**** **** **** {cardDetails?.last4}
					</p>
					<p className={"col-lg-3 m-0"}>
						{cardDetails?.exp_month}/{cardDetails?.exp_year}
					</p>
					<div className="col-lg-1">
						<i
							onClick={() =>
								props.setStripeToken({ generate: false, token: "" })
							}
							className={"col-1  text-danger fa fa-trash"}
						></i>
					</div>
				</div>
			) : (
				<>
					<div className="form-control py-3 mb-2">
						<CardHandler />
					</div>
					<button
						type={"button"}
						onClick={() =>
							props?.customFunction
								? onDirectTokenHandler()
								: stripeTokenGenerator()
						}
						disabled={loading}
						className="btn-donate"
					>
						{loading ? <i className={"fa fa-spin fa-spinner"}></i> : "Add Card"}
					</button>
				</>
			)}
		</>
	);
};

const StripeInjected = (props) => {
	return (
		<ElementsConsumer>
			{({ stripe, elements }) => (
				<Component
					stripeToken={props?.stripeToken}
					setStripeToken={props?.setStripeToken}
					stripe={stripe}
					elements={elements}
					customFunction={props?.customFunction}
				/>
			)}
		</ElementsConsumer>
	);
};

export default StripeInjected;
