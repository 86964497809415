import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getPageRequest} from "../../redux/Setting/Action";
import InnerBanner from "../../components/InnerBanner/InnerBanner";
import ListSection from "./ListSection";
import '../../assets/css/blogs.css';
import {getBlogRequest, getBlogsRequest} from "../../redux/Blogs/Action";
import {useParams} from "react-router-dom";
import DetailSection from "./DetailSection";
import {setLoader} from "../../redux/Loader/Reducer";

const BlogDetail = () => {

	const dispatch = useDispatch();
	const { id } = useParams();
	const blog = useSelector(state => state?.blogs?.blog);

	const get = async () => {
		dispatch(setLoader(true));
		await dispatch(getBlogRequest(id));
		window.scrollTo(0, 0);
		dispatch(setLoader(false));
	}

	useEffect(()=>{
		window.scrollTo(0, 0);
		get();
	},[])

	return (
		<>
			{/* Banner Start Here */}
			<InnerBanner PageName={'Blogs Detail'}/>
			{/* Banner End Here */}
			<DetailSection blog={blog} />
		</>
	);
};

export default BlogDetail;
