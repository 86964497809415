import React, { useEffect, useState } from "react";
import {
	CookiesImg,
	RibbonImg2,
	RibImg,
	ShopGroup,
} from "../../constant/Index";
import "../../assets/css/about-us.css";
import InnerBanner from "../../components/InnerBanner/InnerBanner";
import Team from "./Team";
import { useDispatch, useSelector } from "react-redux";
import { getAboutRequest } from "../../redux/Setting/Action";
import { filePreviewer } from "../../constant/helpers";
import { setLoader } from "../../redux/Loader/Reducer";

const About = () => {
	const dispatch = useDispatch();
	const [toggle, setToggle] = useState(false);
	const about = useSelector((state) => state?.setting?.about);
	const fact = useSelector((state) => state?.setting?.fact);
	const get = async () => {
		dispatch(setLoader(true));
		await dispatch(getAboutRequest());
		window.scrollTo(0, 0);
		dispatch(setLoader(false));
	};

	useEffect(() => {
		window.scrollTo(0, 0);
		get();
	}, []);
	return (
		<>
			{/* InnerBanner Start Here */}
			<InnerBanner PageName="About Us" />
			{/* InnerBanner End Here */}

			{/* Index Sec Start Here */}
			<section className="about-us-main-page">
				<div className="side-image-1-row">
					<img src={CookiesImg} className="img-fluid cookies" alt="" />
					<img src={RibImg} className="img-fluid rib" alt="" />
					<img src={RibbonImg2} className="img-fluid link" alt="" />
					<img src={ShopGroup} className="img-fluid links" alt="" />
				</div>
				<div className="container">
					<div className="row">
						<div className="col-lg-6 align-self-center">
							<div
								className="about-heading wow animate__animated animate__fadeInRight"
								style={{
									height: !toggle ? "19em" : "100%",
									overflow: "hidden",
								}}
							>
								{/* <h2>
									Why we support pediatric <br />
									cancer research:
								</h2> */}
								<span
									dangerouslySetInnerHTML={{ __html: about?.section_1_content }}
								></span>
							</div>
							{!toggle && (
								<button
									onClick={() => setToggle(true)}
									className=" btn1"
									style={{ width: "auto" }}
								>
									Read more
								</button>
							)}
						</div>
						<div className="col-lg-6">
							<div className="row">
								<div className="col-lg-6">
									<div className="about-img-wrapper ">
										<figure>
											<img
												src={filePreviewer(about?.section_1_image_one)}
												className="img-fluid"
												alt=""
											/>
											<img
												src={filePreviewer(about?.section_1_image_three)}
												className="img-fluid"
												alt=""
											/>
										</figure>
									</div>
								</div>
								<div className="col-lg-6">
									<div className="about-img-wrapper1">
										<figure>
											<img
												src={filePreviewer(about?.section_1_image_two)}
												className="img-fluid"
												alt=""
											/>
											<img
												src={filePreviewer(about?.section_1_image_four)}
												className="img-fluid"
												alt=""
											/>
										</figure>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* Index Sec End Here */}

			{/* Index Second Sec Start Here */}
			<section className="about-us-second-sec only-about">
				<div className="container">
					<div className="row">
						<div className="col-lg-6 col-md-12">
							<div className="second-img-wrapper">
								<figure>
									<img
										src={filePreviewer(about?.section_2_image)}
										className="img-fluid"
										alt=""
									/>
								</figure>
							</div>
						</div>
						<div className="col-lg-6 col-md-12">
							<div className="heading-about-img-wrapper wow animate__animated animate__fadeInLeft">
								{/* <h4>What we Do</h4> */}
								{/* <h2>
									We bake cookies to help fund research into Pediatric Cancers
								</h2>
								<p>
									It started in 2006 with Courtney's brain tumor diagnosis
									during her freshman year of high school. Since then, Courtney
									,her family and friends have baked cookies & treats to raise
									money in the effort to help treat & cure pediatric cancer.
								</p> */}
								<span
									dangerouslySetInnerHTML={{ __html: about?.section_2_content }}
								></span>

								<div className="row">
									{fact?.map((data, index) => (
										<div className="col-lg-4 col-md-4" key={index}>
											<div className="img-heading-wrapper">
												<img
													src={filePreviewer(data?.icon)}
													className="img-fluid"
													alt=""
												/>
												<h3>{data?.title}</h3>
												<p>{data?.description}</p>
											</div>
										</div>
									))}
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* Index Second Sec End Here */}

			{/* Team Sec Start Here */}
			<Team />
			{/* Team Sec End Here */}
		</>
	);
};

export default About;
