import React, { useEffect, useState } from "react";
import InnerBanner from "../../components/InnerBanner/InnerBanner";
import '../../assets/css/buckeyes-product-details.css';
import { useDispatch, useSelector } from "react-redux";
import { getProductRequest, getRelatedProductsRequest } from "../../redux/Products/Action";
import BottomSection from "./BottomSection";
import { useParams } from "react-router-dom";
import DetailSection from "../../components/Shop/DetailSection";
import { addNewItem, updateQty, updateQtyNumber } from "../../redux/Cart/Reducer";
import { setError } from "../../redux/Errors/Reducer";
import { changeElementValueInArray } from "../../constant/helpers";
import { setLoader } from "../../redux/Loader/Reducer";
import {getWishListRequest} from "../../redux/Wishlist/Action";


const Detail = () => {

    const { id } = useParams();
    const dispatch = useDispatch();
    const [qty, setQty] = useState(1);
    const isLogin = useSelector(( state ) => state?.auth?.isLogin );
    const cart = useSelector(state => state?.cart?.items);

    const getProduct = async () => {
        dispatch(setLoader(true));
        await dispatch(getProductRequest(id));
        if(isLogin) await dispatch(getWishListRequest());
        // await dispatch(getRelatedProductsRequest());
        window.scrollTo(0, 0);
        dispatch(setLoader(false));
    }

    const addToCart = (item) => {
        let cartItem = cart?.find((val) => parseInt(val?.id) === parseInt(item?.id));
        if (cartItem) {

            if (cartItem?.qty >= item?.stock_qty || (cartItem?.qty + qty) > item?.stock_qty) {
                dispatch(setError([{
                    message: 'Out of stock!', color: 'warning'
                }]));
            } else {
                dispatch(updateQtyNumber({ id: item?.id, qty: cartItem?.qty + qty }));
                // dispatch(updateQty(item?.id));
                dispatch(setError([{
                    message: 'Cart Updated Successfully!', color: 'success'
                }]));
                setQty(0);
                window.scrollTo(0, 0);
            }
        } else {
            if (qty > item?.stock_qty) {
                dispatch(setError([{
                    message: 'Out of stock!', color: 'warning'
                }]));
            } else {
                dispatch(addNewItem({ ...item, qty: qty }));
                dispatch(setError([{
                    message: 'Successfully Added to Cart!', color: 'success'
                }]));
                setQty(0);
                window.scrollTo(0, 0);
            }
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        getProduct();
        return () => { }
    }, [id])

    return (
        <>
            {/* InnerBanner Start */}
            <InnerBanner PageName={'Product Details'} />
            {/* InnerBanner End */}

            <DetailSection addToCart={addToCart}
                qty={qty} setQty={setQty}
            // item={item}
            />
        </>
    );
};

export default Detail;
