import React from "react";
import { useSelector } from "react-redux";
import { filePreviewer } from "../../constant/helpers";
import {
	CookiesImg,
	RibbonImg2,
	RibImg,
	RingImg,
	WeImg,
} from "../../constant/Index";
import {Link} from "react-router-dom";
import {ABOUT} from "../../routes/RouteLinks";

const WhatWeDo = () => {
	const home = useSelector(state=> state?.setting?.home);
	return (
		<>
			<section className="what_we_do">
				<div className="we-img-wrapper">
					<img src={RibImg} className="rib" alt="" />
					<img src={RingImg} className="ring" alt="" />
				</div>
				<div className="container">
					<div className="row">
						<div className="col-lg-6 align-self-center">
							<div className="content-wrapper wow animate__animated animate__fadeInLeft">
								<div dangerouslySetInnerHTML={{__html : home?.section_2_content}}></div>
								{/* <h2>
									We bake cookies to help fund research into Pediatric Cancers
								</h2>
								<p>
									It started in 2006 with Courtney's brain tumor diagnosis
									during her freshman year of high school. Since then, Courtney
									, her family and friends have baked cookies & treats to raise
									money in the effort to help treat & cure pediatric cancer.
								</p> */}
								<div className="btn-wrapper">
									<Link to={ABOUT} className="btn">
										About Us
									</Link>
									<div className="near-wrapper">
										{/* <h6>Nearly</h6> */}
										<span dangerouslySetInnerHTML={{__html: home?.donated_content}}></span>
									</div>
								</div>
							</div>
						</div>
						<div className="col-lg-6">
							<div className="img-wrapper wow animate__animated animate__fadeInRight">
								<figure>
									<img src={filePreviewer(home?.section_2_image)} className="img-fluid" alt="" />
								</figure>
							</div>
						</div>
					</div>
				</div>
				<div className="we-img1-wrapper">
					<img src={RibbonImg2} className="ribion" alt="" />
					<img src={CookiesImg} className="cookies" alt="" />
				</div>
			</section>
		</>
	);
};

export default WhatWeDo;
