import { createSlice } from '@reduxjs/toolkit'


export const Cart = createSlice({
    name: 'cart',
    initialState : {
        items : [],
    },
    reducers: {
        addNewItem : (state,action) => {
            state.items = [
                ...state.items,
                {...action.payload}
            ]
        },
        removeItem : (state,action) => {
            state.items = [
                ...state.items.filter((val)=>val?.id !== action.payload)
            ]
        },
        removePlattersItem : (state,action) => {
            state.items = [
                ...state.items.filter((val)=>val?.id !== action.payload?.id && val?.product_type !== action.payload?.product_type)
            ]
        },
        removeAll : (state,action) => {
            state.items = []
        },
        updateQty : (state,action) => {
            let newItems = [...state.items];
            let index = newItems.findIndex( value => value?.id === action?.payload);
            if(index >= 0 ){
                let item = newItems.find(value => value?.id === action.payload);
                newItems[index] = {
                    ...item,
                    qty : item?.qty + 1
                }
            }
            state.items = newItems;
        },
        updateQtyNumber : (state,action) => {
            let newItems = [...state.items];
            let index = newItems.findIndex( value => value?.id === action?.payload?.id);
            if(index >= 0 ){
                let item = newItems.find(value => value?.id === action.payload?.id);
                newItems[index] = {
                    ...item,
                    qty : action.payload?.qty
                }
            }
            state.items = newItems;
        }
    },
})

// Action creators are generated for each case reducer function
export const { addNewItem , removeItem , updateQty , removeAll , updateQtyNumber , removePlattersItem } = Cart.actions

export default Cart.reducer