import React, {useEffect, useState} from "react";
import InnerBanner from "../../components/InnerBanner/InnerBanner";
import '../../assets/css/cart.css';
import {useDispatch} from "react-redux";
import {getProductsRequest} from "../../redux/Products/Action";
import BottomSection from "./BottomSection";

const Cart = () => {

    const dispatch = useDispatch();

    const getProducts = async () => {
       await dispatch(getProductsRequest(null));
    }

    useEffect(()=>{
        window.scrollTo(0, 0);
        getProducts();
        return () => {}
    },[])

    return (
        <>
            {/* InnerBanner Start */}
            <InnerBanner PageName={'CART'}/>
            {/* InnerBanner End */}

            <BottomSection />
        </>
    );
};

export default Cart;
