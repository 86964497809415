import {createAsyncThunk} from "@reduxjs/toolkit";
import {applesApi, bagsOfPretzelsApi, candyApi, cookiesApi, oreosApi, rodsApi} from "../../Netwrok/api";
import axios from "axios";
import {headers} from "../headers";
import {setError} from "../Errors/Reducer";
import {responseError} from "../../Netwrok/responseHandler";
import {setApples, setBags, setCandy, setCookies, setOreos, setRods} from "./reducer";


export const getCookiesRequest = createAsyncThunk('get-cookies-request',async (
    url = null,
    {
        dispatch,
        rejectWithValue ,
        fulfillWithValue
    })=>{
    try {
        let resp = await axios.get(cookiesApi,headers());
        dispatch(setCookies(resp.data.response.data));
        return fulfillWithValue(true);
    }catch (e){
        dispatch(setError(responseError(e)));
        return  rejectWithValue(false);
    }
});

export const getApplesRequest = createAsyncThunk('get-apples-request',async (
    url = null,
    {
        dispatch,
        rejectWithValue ,
        fulfillWithValue
    })=>{
    try {
        let resp = await axios.get(applesApi,headers());
        dispatch(setApples(resp.data.response.data));
        return fulfillWithValue(true);
    }catch (e){
        dispatch(setError(responseError(e)));
        return  rejectWithValue(false);
    }
});

export const getRodsRequest = createAsyncThunk('get-rods-request',async (
    url = null,
    {
        dispatch,
        rejectWithValue ,
        fulfillWithValue
    })=>{
    try {
        let resp = await axios.get(rodsApi,headers());
        dispatch(setRods(resp.data.response.data));
        return fulfillWithValue(true);
    }catch (e){
        dispatch(setError(responseError(e)));
        return  rejectWithValue(false);
    }
});


export const getOreosRequest = createAsyncThunk('get-oreos-request',async (
    url = null,
    {
        dispatch,
        rejectWithValue ,
        fulfillWithValue
    })=>{
    try {
        let resp = await axios.get(oreosApi,headers());
        dispatch(setOreos(resp.data.response.data));
        return fulfillWithValue(true);
    }catch (e){
        dispatch(setError(responseError(e)));
        return  rejectWithValue(false);
    }
});


export const getCandyRequest = createAsyncThunk('get-candy-request',async (
    url = null,
    {
        dispatch,
        rejectWithValue ,
        fulfillWithValue
    })=>{
    try {
        let resp = await axios.get(candyApi,headers());
        dispatch(setCandy(resp.data.response.data));
        return fulfillWithValue(true);
    }catch (e){
        dispatch(setError(responseError(e)));
        return  rejectWithValue(false);
    }
});

export const getBagsRequest = createAsyncThunk('get-bags-request',async (
    url = null,
    {
        dispatch,
        rejectWithValue ,
        fulfillWithValue
    })=>{
    try {
        let resp = await axios.get(bagsOfPretzelsApi,headers());
        dispatch(setBags(resp.data.response.data));
        return fulfillWithValue(true);
    }catch (e){
        dispatch(setError(responseError(e)));
        return  rejectWithValue(false);
    }
});
