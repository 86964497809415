import React, {useEffect} from "react";
import ContentSection from "./ContentSection";
import {useDispatch, useSelector} from "react-redux";
import {getPageRequest} from "../../redux/Setting/Action";
import InnerBanner from "../../components/InnerBanner/InnerBanner";
import {setLoader} from "../../redux/Loader/Reducer";

const PrivacyPolicy = () => {

	const dispatch = useDispatch();
	const page = useSelector(state => state?.setting?.page);

	const get = async () => {
		dispatch(setLoader(true));
		await dispatch(getPageRequest('privacy-policy'));
		window.scrollTo(0, 0);
		dispatch(setLoader(false));
	}

	useEffect(()=>{
		window.scrollTo(0, 0);
		get();
	},[])

	return (
		<>
			{/* Banner Start Here */}
			<InnerBanner PageName={'Privacy Policy'}/>
			{/* Banner End Here */}
			<ContentSection content={page} />
		</>
	);
};

export default PrivacyPolicy;
