import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { filePreviewer } from "../../constant/helpers";
import { FactIcon1, FactIcon2, FactIcon3, FactImg } from "../../constant/Index";
import { getFactRequest } from "../../redux/Setting/Action";

const Facts = () => {
	const dispatch = useDispatch();
	const home = useSelector((state) => state?.setting?.home);
	const fact = useSelector((state) => state?.setting?.fact);

	const get = async () => {
		await dispatch(getFactRequest());
	};

	useEffect(() => {
		get();
	}, []);

	return (
		<>
			<section className="getcookies">
				<div className="container">
					<div className="row">
						<div className="col-lg-6 p-lg-0">
							<div className="img-wrapper wow animate__animated animate__fadeInLeft">
								<figure>
									<img
										src={filePreviewer(home?.section_1_image)}
										className="img-fluid"
										alt=""
									/>
								</figure>
							</div>
						</div>
						<div className="col-lg-6 p-lg-0">
							<div className="cookies-content-wrapper wow animate__animated animate__fadeInRight">
								{/* <h6>Pediatric cancer facts</h6>
								<h2>
									Get cookies to help Fund treatment & research for pediatric
									Cancer
								</h2>
								<p>
									Childhood cancer is the leading cause of death by disease in
									children under the age of 19 in the US.
								</p> */}
								<div
									dangerouslySetInnerHTML={{ __html: home?.section_1_content }}
								></div>
								<div className="boxes-wrapper">
									{fact?.map((data, index) => (
										<div className="box-wrapper" key={index}>
											<div className="img-wrapper">
												<figure>
													<img
														src={filePreviewer(data?.icon)}
														className="img-fluid"
														alt=""
													/>
												</figure>
											</div>
											<div className="box-content-wrapper">
												<h3>{data?.title}</h3>
												<p>{data?.description}</p>
											</div>
										</div>
									))}
									{/* <div className="box-wrapper">
										<div className="img-wrapper">
											<figure>
												<img src={FactIcon1} className="img-fluid" alt="" />
											</figure>
										</div>
										<div className="box-content-wrapper">
											<h3>2x</h3>
											<p>
												Childhood cancer survivors are twice as likely to suffer
												chronic health conditions
											</p>
										</div>
									</div>
									<div className="box-wrapper">
										<div className="img-wrapper">
											<figure>
												<img src={FactIcon2} className="img-fluid" alt="" />
											</figure>
										</div>
										<div className="box-content-wrapper">
											<h3>1 in 285</h3>
											<p>
												Children in the US will be diagnosed with cancer by the
												time they are 20 years old
											</p>
										</div>
									</div>
									<div className="box-wrapper">
										<div className="img-wrapper">
											<figure>
												<img src={FactIcon3} className="img-fluid" alt="" />
											</figure>
										</div>
										<div className="box-content-wrapper">
											<h3>47</h3>
											<p>kids in US are diagnosed with cancer each day</p>
										</div>
									</div> */}
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default Facts;
