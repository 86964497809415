import React, { useEffect } from "react";
import InnerBanner from "../../components/InnerBanner/InnerBanner";
import '../../assets/css/cart.css';
import {useDispatch} from "react-redux";
import BottomSection from "./BottomSection";
import {getWishListRequest} from "../../redux/Wishlist/Action";
import {setLoader} from "../../redux/Loader/Reducer";

const WishList = () => {

    const dispatch = useDispatch();

    const getList = async () => {
        dispatch(setLoader(true));
        await dispatch(getWishListRequest(null));
        dispatch(setLoader(false));
    }

    useEffect(()=>{
        window.scrollTo(0, 0);
        getList();
        return () => {}
    },[])

    return (
        <>
            {/* InnerBanner Start */}
            <InnerBanner PageName={'WISHLIST'}/>
            {/* InnerBanner End */}

            <BottomSection />
        </>
    );
};

export default WishList;
