import React from 'react';
import StripeElementsWrapper from "../Stripe/StripeElementsWrapper";
import StripeInjected from "../Stripe/StripeInjected";
import PaypalWrapper from "../Paypal/PaypalWrapper";


const PaymentMethodWrapper = (props) => {
    if (props.type === "stripe") {
        return (
            <StripeElementsWrapper>
                {props?.children}
            </StripeElementsWrapper>
        )
    } else {
        return <>{props?.children}</>
        // return <PaypalWrapper>{props?.children}</PaypalWrapper>
    }
}

export default PaymentMethodWrapper;